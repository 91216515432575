
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class SmallDrones extends Vue {
        // Fields

        // Data function
        private data() {
            return {
                droneIconPathAndName:
                    require("@/assets/images/icon_drone.png"),

                droneAnafiAi4gPathAndName:
                    require("@/assets/images/parrot-anafi-ai-4g.png"),
            };
        }
    }
